<template>
  <b-modal
    no-close-on-esc
    no-close-on-backdrop
    id="modal-whatsapp-note"
    ref="my-modal"
    modal-class="modal-primary"
    :title="`Send note to WhatsApp`"
    hide-footer
    centered
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        :state="whatsappNoteState"
        label="Enter your WhatsApp note"
        label-for="name-input"
        invalid-feedback="Note must be at least 2 characters long"
      >
        <b-form-textarea
          id="textarea"
          v-model="whatsappNote"
          :state="whatsappNoteState"
          placeholder="Enter your WhatsApp note here"
          rows="3"
          max-rows="3"
        ></b-form-textarea>
      </b-form-group>
      <div class="footer-addnote">
        <b-button class="mr-1" variant="outline-primary" @click="close" pill>
          Close
        </b-button>
        <b-button type="submit" :disabled="loading" variant="primary" pill>
          <b-spinner class="mr-1" v-if="loading" small />
          Send Note
        </b-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import Whatsapp from "@/services/whatsapp.service.js";
import {
  BButton,
  BFormGroup,
  BFormTextarea,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: ["ordersId"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    loading: false,
    whatsappNote: "",
    whatsappNoteState: null,
    sendVerificationModalId: "modal-send-to-whatsapp-check",
  }),
  methods: {
    checkFormValidity() {
      const valid =
        this.$refs.form.checkValidity() && this.whatsappNote.length > 2;
      this.whatsappNoteState = valid;
      return valid;
    },
    resetModal() {
      this.whatsappNote = "";
      this.whatsappNoteState = null;
    },
    close() {
      this.$nextTick(() => {
        this.$refs["my-modal"].toggle("#toggle-btn");
      });
    },
    async handleSubmit() {
      if (!this.checkFormValidity()) return;
      this.loading = true;
      try {
        const result = await Whatsapp.sendToWhatsapp({
          body: this.whatsappNote,
        });
        if (result.status == 200) {
          this.notify({
            variant: "success",
            text: "Note has been sent successfully.",
          });
          this.close();
        }
      } catch (error) {
        let message = "";
        if (
          error?.response?.status == 422 &&
          error.response.data.status == "not_verified"
        ) {
          this.$bvModal.show(this.sendVerificationModalId);
          message = error.response.data.message;
        } else {
          message = error?.response?.data?.message ?? "Invalid error occurred.";
        }
        this.notify({
          variant: "danger",
          text: message,
        });
      } finally {
        this.loading = false;
      }
    },
    notify({ variant = "success", text = "" } = {}) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },
  },
  components: {
    BButton,
    BFormGroup,
    BFormTextarea,
    BListGroup,
    BListGroupItem,
    BModal,
  },
};
</script>
<style lang="scss" scoped>
.footer-addnote {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
