import vue from "vue";
import api from "@axios";
import store from "@/store";
import { convertHTMLEntity } from "@/global/functions.js";

// Check/Order service "IMEI CHECK"
class ImeiCheck {
    
    // Single check
    static async single(formData, servicePrice) {
        api.defaults.headers.post['version'] = 'v3.6.0' // for post requests
        const { data } = await api.post("/imei/single", formData)
        // Decrease Balance
        store.commit("HANDLE_CREDIT", -servicePrice);
        return data
    }
    static async bulk({ imei, service_id, mail, ...rest }) {
        const imeis = [...new Set(imei.split("\n"))].filter((e) => e.trim());
        api.defaults.headers.post['version'] = 'v3.6.0' // for post requests
        const { data } = await api.post("/imei/bulk", { imeis, service_id, mail, ...rest })
        return data
    }
    // Open POPUP in error case
    static async openPopup(e) { // e = error response
        const { response: resErr, popup, errors } = e.response?.data
        const error =
            e.response?.status === 403 ? resErr :
                e.response?.status === 422
                    ? Object.values(errors)[0][0]
                    : "Server error..";

        console.log(e.response?.data);
        (popup || e.response?.status === 422) && await vue.prototype.$swal({
            title: 'Error',
            html: convertHTMLEntity(error),
            icon: 'error',
            confirmButtonText: 'Ok, understand',
            customClass: {
                confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
        })
    }
}

export default ImeiCheck