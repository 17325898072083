<template>
  <div class="card-container focus-primary">
    <validation-observer ref="checkStep">
      <b-card-body>
        <b-form @submit.prevent="submit">
          <!-- IMEI -->
          <b-form-group>
            <validation-provider
                #default="{ errors }"
                name="IMEI"
                rules="required"
            >
              <b-input-group>
                <b-input-group-prepend class="icon" is-text>
                  <feather-icon icon="SmartphoneIcon"/>
                </b-input-group-prepend>
                <!-- Multi Line -->
                <b-form-textarea
                    v-if="bulk"
                    v-model="imei.multi"
                    class="input-imei"
                    placeholder="1 IMEI/SN per Line
Max 50.000 imeis

(if realtime process stuck,
 check the history page)"

                    rows="5"
                    max-rows="15"
                />
                <!--Single Line -->
                <b-form-input
                    v-else
                    @input="insertImei"
                    :value="imei.single"
                    class="input-imei"
                    autocomplete="off"
                    maxlength="20"
                    placeholder="Enter IMEI/Serial "
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }} {{ err }} </small>
            </validation-provider>
          </b-form-group>
          <!-- Services -->
          <validation-provider
              #default="{ errors }"
              name="service"
              rules="required"
          >
            <SelectBox2
                v-model="service"
                :list="services"
                icon="LayersIcon"
                placeholder="Select Services"
                label="name"
                :price="true"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form>
      </b-card-body>
      <b-card-footer class="footer-actions text-center">
        <b-button @click="submit" size="lg" variant="gradient-primary" pill>
          Check
        </b-button>
      </b-card-footer>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BButton,
  BCardFooter,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormGroup,
} from 'bootstrap-vue'
import SelectBox2 from '@/components/UI/SelectBox2.vue'
import ServiceApi from '@/services/services.service.js'
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from 'vee-validate'
import { required, email } from '@validations'

setInteractionMode('passive')

export default {
  props: ['bulk'],
  data: () => ({
    err: null,
    imei: {
      single: null,
      multi: null,
    },
    service: null, // Service ID
    services: null,
    // validation rules
    required,
    email,
  }),
  watch: {
    service(val) {
      const service = this.services?.find((item) => item.id == val)
      this.$emit('setService', service)
    },
  },
  async mounted() {
    this.services = await ServiceApi.fetch()
    const ps = this.$route.params
    const serviceId = ps.service?.id
    this.service = Number.isInteger(serviceId) ? serviceId : null
    if (ps.bulk) {
      this.$emit('handleBulk', true)
      this.imei.multi = ps.imei
    } else {
      this.imei.single = ps.imei
    }
    const savedService = localStorage.getItem('saved_service')
    if (savedService && !this.service) {
      this.service = parseInt(savedService)
    }
  },
  methods: {

    valid_imei(value) {
// accept only digits, dashes or spaces
      if (/[^0-9-\s]+/.test(value)) return false
// The Luhn Algorithm. It's so pretty.
      var nCheck = 0,
          nDigit = 0,
          bEven = false
      value = value.replace(/\D/g, '')
      for (var n = value.length - 1; n >= 0; n--) {
        var cDigit = value.charAt(n),
            nDigit = parseInt(cDigit, 10)
        if (bEven) {
          if ((nDigit *= 2) > 9) nDigit -= 9
        }
        nCheck += nDigit
        bEven = !bEven
      }
      return (nCheck % 10) == 0
    },

    insertImei(value) {
      //  this.imei.single = value.toUpperCase().replace(" ", "");
      this.imei.single = value.toUpperCase()
          .replace(/[^a-zA-Z0-9]/g, '')

      if (this.imei.single.length == 15) {

        if (!this.valid_imei(this.imei.single)) {
          this.err = 'Invalid IMEI'
        }
      }
      if (this.imei.single.length > 15) {
        this.err = 'IMEI cannot be longer than 15 digits.'
      }
      if (this.imei.single.length < 15) {
        this.err = null
      }
    },

    submit() {
      const imei = this.bulk ? this.imei.multi : this.imei.single
      this.$refs.checkStep.validate()
          .then(async (success) => {
            if (success) {
              this.$emit('submit', imei)
            }
          })
    },
  },
  components: {
    BCard,
    BButton,
    BCardBody,
    BCardFooter,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    SelectBox2,
    BFormTextarea,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },
}
</script>

<style lang="scss" scoped>
.card-container {
  max-width: 500px;
  margin: 0 auto;
}

.input-imei {
  border-left: none;
  text-align: center;
}

.footer-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: none !important;
  padding-top: 0;

  .action {
    display: flex;
    flex-direction: column;
  }
}
</style>
